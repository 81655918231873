import 'vite/modulepreload-polyfill';

import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

import { WindowUtils } from '@tokilab/helpers/windowUtils';
import Header from '@/scripts/components/header';

// import TokiDropdown from '@/scripts/components/tokiDropdown';
import TokiModal from '@/scripts/components/tokiModal';
// import TokiAccordeons from '@/scripts/components/tokiAccordeons';
// import TokiTabs from '@/scripts/components/tokiTabs';

import { scrollToEl } from '@tokilab/helpers/helpers';

window.tokiWU = new WindowUtils();
window.tokiWU.scrollToEl = (target) => scrollToEl(target);

function initTokiComponents() {
    // new TokiDropdown();
    new TokiModal();
    // new TokiAccordeons();
    // new TokiTabs();
}

if (window.Alpine) {
    initTokiComponents();
} else {
    document.addEventListener('alpine:init', initTokiComponents);
}

document.addEventListener('DOMContentLoaded', function () {
    if (!window.Alpine && typeof Alpine !== 'undefined') {
        window.Alpine = Alpine;
    }

    Alpine.plugin(collapse);
    Alpine.start();

    if (typeof Header !== 'undefined') {
        window.header = new Header();
    }
});
