import Alpine from 'alpinejs';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

import { getParents } from '@tokilab/helpers/helpers';

// import Headroom from 'headroom.js';
// import BigMenu from './bigMenu';

class Header {
    constructor() {
        this.container = document.querySelector('.header__wrapper');
        this.modalMenuEl = document.getElementById('modalMenuMobile');

        // this.initAppearHideMenu = this.initAppearHideMenu.bind(this);
        this.responsiveMenu = this.responsiveMenu.bind(this);
        this.bigMenu = this.bigMenu.bind(this);

        if (this.container) {
            this.init();
        }
    }

    init() {
        if (!window.Alpine) window.Alpine = Alpine;
        // this.initAppearHideMenu();
        this.responsiveMenu();
        this.bigMenu();

        // this.bigMenu = new BigMenu(this.container);
    }

    responsiveMenu() {
        gsap.utils.toArray('.modal-menu-mobile__wrapper .header__nav-primary--menu li.menu-item-has-children > a').forEach(item => {
            const back_li = document.createElement('li');
            back_li.classList.add('menu-item__back');
            const back_a = document.createElement('a');
            back_a.setAttribute('href', 'javascript:void(0)');
            back_a.setAttribute('title', window.backLabel);
            back_a.setAttribute('rel', 'nofollow noopener');
            back_a.innerHTML = `<span>${window.backLabel}</span>`;
            back_li.prepend(back_a);
            item.nextElementSibling.prepend(back_li);
        });

        document.addEventListener('click',function(e){
            if(e.target && e.target.className === 'menu-item__back'){
                e.target.closest('.sub-menu').classList.remove('is-visible');
                e.target.closest('.sub-menu').closest('.menu-item.menu-item-has-children').classList.remove('active');
            }
        });
    }

    bigMenu() {
        const that = this;
        gsap.utils.toArray('.header__nav-primary--menu > .menu-item.menu-item-has-children > a').forEach(item => {
            // item.classList.add('enabled');
            const link = item;
            // ['click','mouseover'].forEach( eventListened => {
            ['click'].forEach( eventListened => {
                item.addEventListener(eventListened, (e) => {
                    e.preventDefault();
                    if(getParents(link, '.menu-item-has-children') && !getParents(link, '.menu-item-has-children')[0].classList.contains('active')) {
                        that.closeSubBigMenu();
                    }
                    if(getParents(link, '.menu-item-has-children')) {
                        getParents(link, '.menu-item-has-children')[0].classList.toggle('active');
                        getParents(link, '.menu-item-has-children')[0].querySelector('.sub-menu').classList.toggle('is-visible');
                        that.container.classList.toggle('menu-open');
                    }
                });
            });
        });

        gsap.utils.toArray('.header__nav-primary--menu > .menu-item:not(.menu-item-has-children)').forEach(item => {
            item.addEventListener('click', () => {
                that.closeSubBigMenu();
            });
        });
        document.querySelector('.header__wrapper').addEventListener('mouseleave', () => {
            that.closeSubBigMenu();
        });
    }

    closeSubBigMenu() {
        const that = this;
        gsap.utils.toArray('.header__nav-primary--menu > .menu-item.menu-item-has-children.active').forEach(item => {
            if(getParents(item, '.menu-item-has-children')) {
                getParents(item, '.menu-item-has-children')[0].classList.remove('active')
                getParents(item, '.menu-item-has-children')[0].querySelector('.sub-menu').classList.remove('is-visible');
                that.container.classList.remove('menu-open');
            }
        });
    }

    initAppearHideMenu() {
        const headerMaster = this.container;

        window.headroom = new Headroom(headerMaster, {
            tolerance: 5, // normally 15 but 5 with lenis
            offset: {
                up: headerMaster.clientHeight,
                down: 50,
            },
        });
        window.headroom.init();
    }
}

export default Header;
