import Alpine from 'alpinejs';

class TokiModal {
    constructor() {
        this.init();
    }

    init() {
        if (!window.Alpine) window.Alpine = Alpine;
        Alpine.data('tokiModal', (obj = {}) => ({
            elementId: obj.elementId || 'tokiModal-noName',
            show: false,
            init() {
                this.$watch('show', (e) => {
                    if (e) {
                        document.dispatchEvent(new Event(`${obj.elementId}_show`));
                    } else {
                        document.dispatchEvent(new Event(`${obj.elementId}_hide`));
                    }
                });
                document.addEventListener(`${obj.elementId}_called`, () => {
                    this.setModal(true);
                });
                document.addEventListener(`${obj.elementId}_force_show`, () => {
                    this.open();
                });
                document.addEventListener(`${obj.elementId}_force_hide`, () => {
                    this.close();
                });
            },
            open() {
                this.show = true;
                document.body.classList.add('modal-open');
            },
            close() {
                this.show = false;
                document.body.classList.remove('modal-open');
            },
            toggle() {
                this.show = !this.show;
            },
            isOpen() {
                return this.show === true;
            },
            setModal(param) {
                if (param) {
                    this.open();
                } else {
                    this.close();
                }
            },
        }));
    }
}

export default TokiModal;
